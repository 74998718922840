*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Istok Web', sans-serif;
}

$pozadina : #EDFFF0;
$menuItem: #EAECEA;
$selectedmenuItem: #0F3115;
$borderContact: #E6E6E6;

@mixin mQ-phones($arg){
    @media screen and (max-width: $arg){
        @content;
    }
}

@mixin mQ-other($min, $max){
    @media screen and (min-width:$min) and (max-width:$max){
        @content;
}
}

@mixin mQ-largest($min){
    @media screen and (min-width: $min){
        @content;
    }
}


body{
    background-color: $pozadina;
    overflow-x:hidden;
}

#main-container{
    display:flex;
    flex-direction: column;
    width:100vw;
    min-height:100vh;
}

#logo-container{
    display:flex;
    width:100vw;
    justify-content: space-around;
    border-bottom: 1px solid black;
    background-color: $selectedmenuItem;
    color:$pozadina;

    img{
        height:60px;
    }

    @include mQ-phones(480px){
  
        img{
            height:35px;
        }
    }

    @include mQ-other(481px, 767px){

        img{
            height:50px;
        }
    }

    @include mQ-other(768px, 1099px){
      img{
        height: 50px;
      }
    }

    @include mQ-other(1100px, 1596px){
        img{
            height:50px;
        }
    }
}

.left-container, .right-container{
    display:flex;
    align-items: center;
    font-weight: bold;
    font-size:1.3em;

    @include mQ-phones(480px){
    font-size:0.515em;
    }

    @include mQ-other(481px, 767px){
        font-size:0.8em;
    }

    @include mQ-other(768px, 1099px){
    font-size:0.9em;
    }

    @include mQ-other(1100px, 1596px){
    font-size:1.1em;
    }
}

#main-middle-container{
display: flex;
flex-direction: column;
align-items: center;
}

//MENU

#menu-items-container{
    position: relative;
    display: flex;
    align-self:center;
    margin-top:20px;
    margin-bottom:20px;
    justify-content: center;

    @include mQ-phones(480px){
        font-size:0.60em;
        margin-top:20px;
    }

    @include mQ-other(481px, 767px){
        margin-left:40px;
        font-size:0.70em;
    }

    @include mQ-other(768px, 1099px){
        font-size:0.85em;
    }

    @include mQ-other(1100px, 1596px){
        font-size:0.9em;
    }
}

.main-menu-item{
    padding:10px 25px;
    border:1px solid black;
    background-color: $menuItem;
    border-radius:1px;
    font-size:1.6em;
    width:120px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;

    &:hover{
        cursor:pointer;
        text-decoration: underline;
        -webkit-box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
        -moz-box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
        box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
    }

    @include mQ-phones(480px){
        padding:3px 6px;
        width: calc(100vw / 6);
        font-size:1em;
    }

    @include mQ-other(481px, 767px){
        padding:5px 10px;
        width:85px;
    }

    @include mQ-other(768px, 1099px){
        padding:5px 10px;
        font-size: 1.1em;
    }

    @include mQ-other(1100px, 1596px){
        padding: 8px 22px;
        font-size:1.1em;
    }

    @include mQ-other(1596px, 2000px){
        width:200px;
        font-size:1.1em;
    }

    @include mQ-largest(2001px){
        width:15.5vw;
        font-size:1.8em;
    }

}

.selected-menu-item{
    background-color: $selectedmenuItem;
    color:white;
   // transform:scale(1.1);
    outline:1px solid white;
    -webkit-box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
    box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
}

#secondary-menu-container{
    position: absolute;
    top:50px;
    left:0px;
    z-index:20;
    display:flex;
    flex-direction: column;
    font-size:0.9em;

    @include mQ-phones(480px){
    font-size:0.9em;
    top:25px;   
    }

    @include mQ-other(481px, 767px){
        font-size:0.7em;
        top:35px;
    }

    @include mQ-other(768px, 1099px){
        font-size:0.70em;
        top:40px;
    }

    @include mQ-other(1100px, 1596px){
        
    }
}

#main-content-container{
    display: flex;
    justify-content: space-between;
    align-self: center;
    width:1000px;
    padding:20px;
    background-color: #A9CBA1;
    border-radius:5px;
    -webkit-box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
    box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);

    @include mQ-phones(480px){
    flex-direction: column;
    align-items: center;
    width:95vw;
    height:auto;
    padding:5px;
    }

    @include mQ-other(481px, 549px){
        width:95vw;
        flex-direction: column;
        height:auto;
        padding:8px;
    }

@include mQ-other(550px, 700px){
    flex-direction: column;
width:95vw;
height:auto;
padding:8px;
}

@include mQ-other(700px, 768px){
width:98vw;
height:auto;
padding:10px;
}

@include mQ-other(769px, 1099px){
    width:90vw;
    height:auto;
    padding:13px;
}


}

#about-us-holder{
    display:flex;
    flex-wrap:wrap;
    padding:30px;
    font-weight: bold;
    font-size:0.9em;
    div:nth-child(1){
        margin-bottom:20px;

    }

    @include mQ-phones(480px){
        div:nth-child(1){
            margin-bottom:15px;
        }
        width:90vw;
        padding:10px;
        margin-bottom:10px;
        font-size:0.65em;   
    }
}

#picture-container{
display:flex;
align-items: center;
justify-content: center;
width:500px;

@include mQ-phones(480px){
    width:90vw;
}
@include mQ-other(481px, 767px){
    width:90vw;
}
@include mQ-other(768px, 1099px){
    width:90vw;
}



}

#picture-holder{
    position: relative;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    -webkit-box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
    box-shadow: -1px 0px 28px -12px rgba(0,0,0,0.75);
    max-width:450px;
    min-height: 270px;
img{
    -webkit-box-shadow: inset 0px 0px 12px -4px rgba(0,0,0,0.55);
-moz-box-shadow: inset 0px 0px 12px -4px rgba(0,0,0,0.55);
box-shadow: inset 0px 0px 12px -4px rgba(0,0,0,0.55);
    max-width:450px;
    max-height:400px;
    &:hover{
        cursor: pointer;
    }
}

@include mQ-phones(480px){
    max-width:300px;
    min-height:150px;
    img{
        max-width:300px;
        max-height:260px;
    }
}

@include mQ-other(481px, 767px){
    max-width:300px;
    min-height:200px;
    img{
        max-width:300px;
        max-height:290px;
    }
}

@include mQ-other(768px, 1099px){
        max-width:300px;
        min-height:170px;
        img{
            max-width:300px;
            max-height:2900px;
        }
}
}

#secondary-picture-holder{
    width:100%;
    display:flex;
    justify-content: space-around;
    img{
        max-height:200px;
        border:1px solid black;
        object-fit:cover
    }

    @include mQ-phones(480px){
        img{
            max-height:100px;
        }
    }
}

#text-container{
    display:flex;
    align-items: center;
    width:450px;

    @include mQ-phones(480px){
    width:80vw;
    }
    
    @include mQ-other(481px, 549px){
        align-self: center;
    }

    @include mQ-other(550px, 767px){
    width:250px;
    }

    @include mQ-other(768px, 1099px){
    width:350px;
    }

    @include mQ-other(1100px, 1596px){
    width:400px;
    } 
}

#text-container-buyers{
    padding: 10px;
    width:100%;
}

#bold-buyers{
    text-decoration: underline;
    font-size:1.5em;
    margin-bottom:20px;
}

.main-paragraph{
    padding:20px;
    text-shadow: 0.5px 0.3px 1px #000000;

    @include mQ-phones(480px){
    padding:5px;
    font-size:0.5em;
    }

    @include mQ-other(481px, 767px){
        padding:10px;
        font-size:0.7em;
    }

    @include mQ-other(768px, 1099px){
        padding:15px;
        font-size:0.8em;
    }

    @include mQ-other(1100px, 1596px){
        font-size:0.9em;
    }
}

#right-main-side{
    padding:20px;
   display:flex;
    flex-direction:column;
    justify-content: space-around;
    align-items: center;
}

#left-carousel, #right-carousel{
svg{
    transform: scale(1.5);
    margin:10px;
    cursor: pointer;
}

@include mQ-phones(480px){
    svg{
        transform:scale(0.9);
    }
}

@media (hover: hover) {
    svg:hover{
        transform:scale(2.5)
    }

    @include mQ-phones(480px){
        svg:hover{
            transform: scale(1.5);
        }
    }
}
}

#left-modal-carousel,#right-modal-carousel{
    svg{
        transform: scale(3);
        margin:10px;
        color:white;
        cursor: pointer;
    }

    @include mQ-phones(480px){
            svg{
                transform:scale(2);
            }
    }

        @media (hover: hover) {
            svg:hover{
                transform: scale(4);
           }
        
           @include mQ-phones(480px){
            svg:hover{
                transform: scale(2.5);
            }
        }

    } 

}

#picture-title{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:0.8em;
    padding:10px;
    color:#5D5D5D;
    text-shadow: 0.5px 0.3px 0px #000000;

    @include mQ-phones(480px){
        font-size:0.5em;
    }
}

//MODAL
#modal-container{
    position:absolute;
    z-index:5;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0,0.90);
}

#modal-holder{
    display:flex;
    height:100vh;
    padding:30px;
    align-items: center;
    justify-content: space-around;
}

#modal-picture-holder{
        display:flex;
        flex-direction: column;

img{
    max-width:80vw;
    max-height:80vh;
    border:2px solid black;
    border-collapse: collapse;
}
}

#picture-modal-title{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:0.8em;
    padding:10px;
    color:black;
    background-color:white;
    text-shadow: 0.5px 0.3px 0px #000000;
    border-top:1px solid black;
    border-left:2px solid black;
    border-right:2px solid black;
    border-bottom:2px solid black;
    @include mQ-phones(480px){
        font-size:0.5em;
    }
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

#contact-map-container{
    display:flex;
    justify-content: space-around;
    align-items: center;
    margin-top:50px;
    margin-bottom:20px;

    @include mQ-phones(480px){
        flex-direction: column;
    }

    @include mQ-other(481px, 549px){
        flex-direction: column;
    }
}

#contact-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border:1px solid black;
    width:20vw;
    height:300px;
    padding:20px;;


    #contact-title{
        font-size:1.5em;
        align-self: center;
        font-weight: bold;
    }

    #contact-fields-container{
        display: flex;
    }

    @include mQ-phones(480px){
        width:60vw;
        height:140px;
        margin-top:20px;

        #contact-title{
            font-size:0.9em;
        }

        #contact-fields-container{
            font-size:0.60em;
        }
    }

    @include mQ-other(481px, 549px){
        width:60vw;
        height:150px;
        margin-top:20px;
        #contact-title{
            font-size:1em;
        }

        #contact-fields-container{
            font-size:0.65em;
        }
    }

    @include mQ-other(550px, 767px){
        width:42vw;
        height:150px;
        #contact-title{
            font-size:1em;
        }

        #contact-fields-container{
            font-size:0.65em;
        }
    }

    @include mQ-other(768px, 1099px){
        width:35vw;
        
        #contact-title{
            font-size:1.15em;
        }

        #contact-fields-container{
            font-size:0.85em;
        }
    }

    @include mQ-other(1100px, 1596px){
        width:25vw;
        #contact-title{
            font-size:1.3em;
        }

        #contact-fields-container{
            font-size:0.9em;
        }
    }
}

#contact-left{
width:50%;

div{
    padding:3px;
    border-bottom:1px solid black;
    p{
        text-align: left;
    }
}
}

#contact-right{
    width:50%;
    div{
        padding:3px;
        border-bottom:1px solid black;
        p{
            
            text-align: right;
        }
    }
}

#map-container{
    p{
        text-align: center;
        font-size:1.5em;
        font-weight: bold;
        margin-bottom:10px;
    }
    @include mQ-phones(480px){
        font-size:0.6em;
    }

    @include mQ-other(481px, 767px){
        font-size:0.7em;
    }

    @include mQ-other(768px, 1099px){
        font-size:0.8em;
    }
    
}

#google-map{
    display:flex;
    justify-content: center;
    align-items: center;
    width:550px;
    height:550px;
    padding:0px;
    border:1px dashed black;

    @include mQ-phones(480px){
        width:300px;
        height:300px;
    }

    @include mQ-other(481px, 767px){
        width:300px;
        height:300px;
    }

    @include mQ-other(768px, 1099px){
        width:400px;
        height:400px;
    }

    @include mQ-other(1100px, 1596px){
        width:450px;
        height: 450px;
    }
}

#footer-container{
    display:flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    color:$pozadina;
    height:40px;
    width:100%;
    text-align: right;
    font-size:1em;
    background-color:$selectedmenuItem;

    div{
        margin-right:30px;
    }
    p{
        display: inline-block;
        height:100%;
        margin: 0px 10px;
    }

    @include mQ-phones(480px){
        font-size:0.4em;
        height:20px;

        div{
            margin-right:10px;
        }
    }

    @include mQ-other(481px, 767px){
        font-size:0.8em;
        height:30px;
    }

    @include mQ-other(768px, 1099px){
        font-size:0.9em;
        height:35px;
    }

    @include mQ-other(1100px, 1596px){
        font-size:1em;
        height:45px;
    }

}

#table-container{
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align:center;
      }
    th{
        padding: 5px;
    }
    table{
        border:2px solid black;
    }

    @include mQ-phones(480px){
        font-size:0.60em;
        margin-top:30px;
    }

    @include mQ-other(481px, 767px){
        font-size:0.70em;
    }

    @include mQ-other(768px, 1099px){
        font-size:0.75em;
        th{
            padding:3px;
        }
    }

    @include mQ-other(1100px, 1596px){
        font-size:0.9em;
    }
}